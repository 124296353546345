body {
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

.printOnly {
  display: none !important;
}

/*
Print stylesheet for HMA Tracking System
Inspired by https://www.noupe.com/design/css-perfect-print-stylesheet-98272.html
lastmodified 11.29.2018
*/

@media print {
  .printOnly {
    display: flex !important;
  }
  .noPrint {
    display: none !important;
  }
  .MuiTooltip-popper {
    display: none;
  }
  /************ Setting content width, unsetting floats and margins ***********/
  #MainBody {
    width: 100%;
    margin: 0;
    float: none;
    font: 13pt 'Roboto', sans-serif;
    line-height: 1.3;
    background: #fff !important;
    color: #000;
    -webkit-print-color-adjust: exact;
  }

  /****************************** Setting margins *****************************/
  @page {
    margin: 0cm;
  }
  @page :left {
    margin: 0cm;
  }
  @page :right {
    margin: 0cm;
  }
  @page :first {
    margin: 0cm 0cm;
  }

  /***** Set font to 16px/13pt, set background to white and font to black. ****/
  h1 {
    font-size: 24pt;
  }
  h2,
  h3,
  h4 {
    font-size: 14pt;
    margin-top: 25px;
  }

  /************************* Defining all page breaks *************************/
  a {
    page-break-inside: avoid;
  }
  blockquote {
    page-break-inside: avoid;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
    page-break-after: avoid;
  }
  tr,
  pre {
    page-break-inside: avoid;
  }
  ul,
  ol,
  dl {
    page-break-before: avoid;
  }
  .pageBreakAfter {
    page-break-after: always;
  }

  /******************************* Handle Links *******************************/
  a:link,
  a:visited,
  a {
    background: transparent;
    color: #520;
    font-weight: bold;
    text-decoration: underline;
    text-align: left;
  }
  a {
    page-break-inside: avoid;
  }
  a[href^='http']:after {
    content: ' < ' attr(href) '> ';
  }
  $a:after > img {
    content: '';
  }
  article a[href^='#']:after {
    content: '';
  }
  a:not(:local-link):after {
    content: ' < ' attr(href) '> ';
  }

  /****************** Hiding Videos, iframes & Other Elements *****************/
  .entry iframe,
  ins {
    display: none;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    line-height: 0pt !important;
    white-space: nowrap;
  }
  .embed-youtube,
  .embed-responsive {
    position: absolute;
    height: 0;
    overflow: hidden;
  }
  #MainMenu,
  #Toolbar,
  #MainBody .HidePrint {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    line-height: 0pt !important;
    white-space: nowrap;
  }

  /************** Adding Messages Before and After Printing **************/
  /* Adding custom messages before and after the content */
  .entry:after {
    content: '\ All Rights Reserved. (c) 2014 - 2016 TechBrain - techbrain.de';
    color: #999 !important;
    font-size: 1em;
    padding-top: 30px;
  }

  #header:before {
    content: '\ Thank you for printing our article. We hope that some of our other articles can catch your eye as well.';
    color: #777 !important;
    font-size: 1em;
    padding-top: 30px;
    text-align: center !important;
  }
}
